import { defineStore } from "pinia";
import { CreatePackageServiceRequest, CreateIndividualServiceRequest, IndividualService, PackagedService, ProductionService, UpdatePackageServiceRequest, UpdateIndividualServiceRequest, isPackageService, isIndividualService } from "../types/services";
import { useServicesStore } from "./services";
import axios from "axios";
import { APIResponse } from "@/modules/core/types";
import endpoints from "../endpoints";
import { useErrorHandling } from "@/modules/core/composables";

export const useServiceStore = defineStore('service', {
    state: () => ({
        serviceId: '',
        service: null as PackagedService|IndividualService|null,
        productionService: null as ProductionService|null,
        isBusy: false
    }),
    getters: {
        packageService: state => isPackageService(state.service) ? state.service : null,
        individualService: state => isIndividualService(state.service) ? state.service : null,
    },
    actions: {
        async getProductionServices () {
            const request = axios.get<APIResponse<ProductionService>>(
                `${endpoints.MEMBERS}/production-services`
            )

            const response = await useErrorHandling(request);

            this.$patch ({
                productionService: response.data.data
            })
        },
        async loadById (serviceId: string) {
            this.$patch({
                serviceId: '',
                service: null,
                isBusy: true
            })

            const request = axios.get<APIResponse<PackagedService|IndividualService>>(
                `${endpoints.MEMBERS}/service-offerings/${serviceId}`,
            )

            const response = await useErrorHandling(request);

            // const service = servicesStore.paginatedResults.items.find(x => x.key === serviceId);

            this.$patch({
                serviceId,
                service: response.data.data,
                isBusy: false
            })
        },
        async updatePackageService (data: UpdatePackageServiceRequest) {
            this.isBusy = true;

            const request = axios.put<APIResponse<PackagedService>>(
                `${endpoints.MEMBERS}/service-offerings/package`,
                data
            )

            const response = await useErrorHandling(request)

            this.$patch({
                service: response.data.data,
                isBusy: false
            })
        },
        async updateIndividualService (data: UpdateIndividualServiceRequest) {
            this.isBusy = true;

            const request = axios.put<APIResponse<IndividualService>>(
                `${endpoints.MEMBERS}/service-offerings/individual`,
                data
            )

            const response = await useErrorHandling(request)

            this.$patch({
                service: response.data.data,
                isBusy: false
            })
        },
        async createPackageService (data: CreatePackageServiceRequest) {
            const request = axios.post<APIResponse<PackagedService>>(
                `${endpoints.MEMBERS}/service-offerings/package`,
                data
            )

            const response = await useErrorHandling(request);

            this.$patch({
                service: response.data.data
            })

            return response.data.data
        },
        async createIndividualService (data: CreateIndividualServiceRequest) {
            const request = axios.post<APIResponse<IndividualService>>(
                `${endpoints.MEMBERS}/service-offerings/individual`,
                data
            )

            const response = await useErrorHandling(request);

            this.$patch({
                service: response.data.data
            })

            return response.data.data
        }
    }
})