import { APIResponse, PaginatedData } from "@/modules/core/types";
import { defineStore } from "pinia";
import { AdvertisementListing } from "../types";
import axios from "axios";
import endpoints from "@/modules/broadcast-portal/endpoints";
import { useErrorHandling } from "@/modules/core/composables";

export const useAdvertisementsStore = defineStore('advertisementsStore', {
    state: () => ({
        advertisementFilters: {
            searchTerms: '',
            pageNumber: 1,
            pageSize: 9,
        },
        advertisementPaginatedResults: null as PaginatedData<AdvertisementListing>|null,
        isBusy: false
    }),
    actions: {
        async loadAdvertisement (suppressAuthToast = false) {
            this.isBusy = true;
            let params: any = { ...this.advertisementFilters }

            try{
                const request = axios.get<APIResponse<PaginatedData<AdvertisementListing>>>(
                    `${endpoints.MEMBERS}/advertisements `,
                    { params }
                )
                const response = await (!suppressAuthToast ? useErrorHandling(request) : request);

                this.$patch({
                    advertisementPaginatedResults: response.data.data
                })
            }
            catch (error) {
                console.log(error);
            }
            finally {
                this.isBusy = false;
            }
        }
    }
})