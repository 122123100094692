import { defineStore } from "pinia";
import { Country, Gender, IdentityType, Nationality, BusinessType, DurationOfWorkMeasurement, ApprovalStatus } from '@/modules/broadcast-portal-post-login/types';

export const useCommonDataStore = defineStore('common-data', {
    state: () => ({
        countries: [] as Array<Country>,
        genders: [] as Array<Gender>,
        identityTypes: [] as Array<IdentityType>,
        nationalities: [] as Array<Nationality>,
        businessTypes: [] as Array<BusinessType>,
        durationOfWorkMeasurements: [] as Array<DurationOfWorkMeasurement>,
        approvalStatuses: [] as Array<ApprovalStatus>,
    }),
})