import { defineStore } from "pinia";
import { AdvertisementListing, UpdateAdvertisenentResponse } from "../types";
import { APIResponse } from "@/modules/core/types";
import axios from "axios";
import endpoints from "@/modules/broadcast-portal/endpoints";
import { useErrorHandling } from "@/modules/core/composables";
import { serialize } from "object-to-formdata";

export const useAdvertisementStore = defineStore('advertisementStore', {
    state: () => ({
        advertisementKey: '',
        advertisement: null as AdvertisementListing|null,
        isBusy: false,
    }),
    actions: {
        async loadById (advertisementKey: string) {
            this.$patch({
                advertisementKey: '',
                advertisement: null,
                isBusy: true,
            })

            const request = axios.get<APIResponse<AdvertisementListing>>(
                `${endpoints.MEMBERS}/advertisements/${advertisementKey}`
            )

            const response = await useErrorHandling(request)

            this.$patch({
                advertisementKey,
                advertisement: response.data.data,
                isBusy: false
            })

            return response.data.data
        },
        async updateCurrentAdvertisement (data: UpdateAdvertisenentResponse) {
            this.isBusy = true;
            const formData = serialize(data, {noFilesWithArrayNotation: true});

            const request = axios.put<APIResponse<AdvertisementListing>>(
                `${endpoints.MEMBERS}/advertisements`,
                formData
            )

            const response = await useErrorHandling(request);

            this.$patch({
                advertisement: response.data.data,
                isBusy: false
            })
        }
    }
})