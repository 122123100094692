import { APIResponse, PaginatedData } from "@/modules/core/types";
import { defineStore } from "pinia";
import { IndividualService, PackagedService } from "../types/services";
import axios from "axios";
import endpoints from "../endpoints";
import { useErrorHandling } from "@/modules/core/composables";

export const useServicesStore = defineStore('services', {
    state: () => ({
        filters: {
            searchTerm: '',
            pageNumber: 1,
            pageSize: 9,
        },
        paginatedResults: null as PaginatedData<PackagedService|IndividualService>|null,

        isBusy: false
    }),
    actions: {
        async getServicesResult (suppressAuthToast = false) {
            this.isBusy = true;
            let params: any = { ...this.filters }

            try{
                const request = axios.get<APIResponse<PaginatedData<PackagedService|IndividualService>>>(
                    `${endpoints.MEMBERS}/service-offerings`,
                    { params }
                )
                const response = await (!suppressAuthToast ? useErrorHandling(request) : request);

                this.$patch({
                    paginatedResults: response.data.data
                })
            }
            catch (error) {
                console.log(error);
            }
            finally {
                this.isBusy = false;
            }
        },
    }
}) 