import { BaseContent } from "@/modules/core/types";
import { ApprovalStatus } from "./auth";

export const isPackageService = (service: any): service is PackagedService => {
    return 'ncContentTypeAlias' in service && service.ncContentTypeAlias === 'packageServiceOffering';
}

export const isIndividualService = (service: any): service is IndividualService => {
    return 'ncContentTypeAlias' in service && service.ncContentTypeAlias === 'individualServiceOffering';
}

export interface ServiceOffering {
    key: string;
    ncContentTypeAlias: string;
    createDateTime: string;
    lastUpdateDateTime: string;
    isDeleted: boolean;
    showPriceInPortal: boolean;
    showOfferingInPortal: boolean;
    approvalStatus: ApprovalStatus;
    approverUserAccountId: number;
    price: number;
    description: string;
    durationOfWork: number;
    durationOfWorkMeasurement: DurationOfWorkMeasurement;
    durationIsDependentOnProject: boolean;
}

export interface PackagedService extends ServiceOffering {
    packageName: string;
    services: Array<ProductionService>;
    ncContentTypeAlias: 'packageServiceOffering';
}

export interface IndividualService extends ServiceOffering {
    service: ProductionService;
    serviceOfferingKey: string;  
    ncContentTypeAlias: 'individualServiceOffering';
}

export interface DurationOfWorkMeasurement extends BaseContent {}

export interface ProductionService extends BaseContent {}

export interface UpdatePackageServiceRequest {
    serviceOfferingKey: string;
    price: number;
    durationOfWork: number;
    durationOfWorkMeasurementKey: string;
    durationIsProjectDependent: boolean;
    packageName: string;
    productionServiceKeys: Array<string>;
    description: string;
}

export interface UpdateIndividualServiceRequest {
    serviceOfferingKey: string;
    price: number;
    durationOfWork: number;
    durationOfWorkMeasurementKey: string;
    durationIsProjectDependent: boolean;
    productionServiceKey: string;
}

export interface CreatePackageServiceRequest {
    price: number;
    durationOfWork: number;
    durationOfWorkMeasurementKey: string;
    durationIsProjectDependent: boolean;
    packageName: string;
    productionServiceKeys: Array<string>;
    description: string;
}

export interface CreateIndividualServiceRequest {
    price: number;
    durationOfWork: number;
    durationOfWorkMeasurementKey: string;
    durationIsProjectDependent: boolean;
    productionServiceKey: string;
    description: string;
}
