import { APIResponse, PaginatedData } from "@/modules/core/types";
import { defineStore } from "pinia";
import { Portfolio, CreatePortfolioRequest, UpdatePortfolioRequest, PortfolioListing } from "../types/portfolio";
import axios from "axios";
import { useErrorHandling } from "@/modules/core/composables";
import endpoints from "../endpoints";
import { serialize } from "object-to-formdata";
import { usePortfoliosStore } from "./portfolios";
import { Video } from "../types";

export const usePortfolioStore = defineStore('portfolio', {
    state: () => ({
        portfolioKey: '',
        portfolio: null as Portfolio|null,
        videoDuration: 0,
        isBusy: false,
        isLoadingVideo: false
    }),
    actions: {
        async loadPortfolioById (portfolioKey: string) {
            this.$patch({
                portfolioKey: '',
                portfolio: null,
                isBusy: true
            })

            const request = axios.get<APIResponse<PortfolioListing>>(
                `${endpoints.MEMBERS}/portfolio-showcases/${portfolioKey}`,
            )

            const response = await useErrorHandling(request);

            this.$patch({
                portfolioKey,
                portfolio: response.data.data.portfolioShowcase,
                videoDuration: response.data.data.videoDurationInSeconds,
                isBusy: false
            })

            return response.data.data.portfolioShowcase
            
        },
        async createNewPortfolio (data: CreatePortfolioRequest) {
            this.isBusy = true;
            const formData = serialize(data);

            const request = axios.post<APIResponse<Portfolio>>(
                `${endpoints.MEMBERS}/portfolio-showcases`,
                formData
            )

            const response = await useErrorHandling(request);

            this.$patch({
                portfolio: response.data.data,
                isBusy: false
            })
        },
        async updateCurrentPortfolio (data: UpdatePortfolioRequest) {
            this.isBusy = true;
            const formData = serialize(data);

            const request = axios.put<APIResponse<Portfolio>>(
                `${endpoints.MEMBERS}/portfolio-showcases`,
                formData
            )

            const response = await useErrorHandling(request);

            this.$patch({
                portfolio: response.data.data,
                isBusy: false
            })
        },
        async getPortfolioVideoTrailer (videoId: string) {
            this.isLoadingVideo = true
            const request = axios.get<APIResponse<string>>(
                `${endpoints.MEMBERS}/portfolio-showcases/video-uploads/signed-tokens?videoId=${videoId}`,
            )

            const response = await useErrorHandling(request)

            this.isLoadingVideo = false
            return response.data.data
        },
        async getPortfolioVideoTrailerDetails (videoUrl: string) {
            this.isLoadingVideo = true
            const request = axios.get<APIResponse<Video>>(
                `/api/v1/video-uploads/details?videoUrl=${videoUrl}`
            )

            const response = await useErrorHandling(request)

            this.isLoadingVideo = false
            return response.data.data.result[0]
        }
    }
})