import { defineStore } from "pinia";
import endpoints from "@/modules/broadcast-portal-post-login/endpoints";
import { useErrorHandling } from "@/modules/core/composables";
import { useLocalStorage } from "@vueuse/core";
import { APIResponse } from "@/modules/core/types";
import axios from "axios";
import Cookies from 'js-cookie';
import appConstants from "../app.constants";
import { ResetPasswordRequest, EmailVerificationRequest, FreelancerMember, FreelancerMemberAuthResponse, PrivacySettingsForm, ProductionHouseMember, ProductionHouseMemberAuthResponse, SignInRequest, SignUpRequest, UpdateCurrentPassword } from "../types";
import { FreelancerProfileDetailsFormFields, ProductionHouseProfileDetailsFormFields } from "../components/registration/types";
import { serialize } from 'object-to-formdata';

const storageKey = 'broadcast-portal.auth';
const rememberKey = 'broadcast-portal.auth.rememberMe';

const emptyAtsAuthState = () => ({
    productionHouseMember: null as ProductionHouseMember|null,
    freelanceMember: null as FreelancerMember|null,
    accessToken: '',
})

export const useBroadcastPortalAuthStore = defineStore('auth', {
    state: () => {
        const rememberMe = useLocalStorage(
            rememberKey,
            true,
            { listenToStorageChanges: false }
        );
        const auth = useLocalStorage(
            storageKey,
            emptyAtsAuthState(),
            { listenToStorageChanges: false }
        );

        return {
            rememberMe,
            auth,
            isBusy: false
        }
    },
    getters: {
        member: state => state.auth.productionHouseMember || state.auth.freelanceMember,
        hasCompletedRegistration (): boolean {
            return this.member?.hasCompletedRegistration ?? false;
        },
        hasAccessToken (): string {
            return this.auth.accessToken ?? '';
        }
    },
    actions:{
        async logout (shouldReload = false, shouldClearParams = false) {
            this.$patch({
                auth: {
                    accessToken: '',
                    freelanceMember: null,
                    productionHouseMember: null
                }
            });

            if (shouldReload) {
                if (shouldClearParams) {
                    const url = new URL(window.location.href);
                    url.search = "";
                    return window.location.href = url.toString();
                }
                window.location.reload()
            }
        },
        async signInAsProductionHouse(payload: SignInRequest) {
            const request = axios.post<APIResponse<ProductionHouseMemberAuthResponse>>(
                `${endpoints.PROD_HOUSE}/sign-ins/username-pwd`,
                { email: payload.email, password: payload.password }
            )
            const response = await useErrorHandling(request);

            this.$patch({
                auth: response.data.data
            })
        },
        async signInAsFreelancer(payload: SignInRequest) {
            const request = axios.post<APIResponse<FreelancerMemberAuthResponse>>(
                `${endpoints.FREELANCER}/sign-ins/username-pwd`,
                { email: payload.email, password: payload.password }
            )
            const response = await useErrorHandling(request);

            this.$patch({
                auth: response.data.data
            })
        },
        async linkedinSignInAsProductionHouse (accessToken: string) {
            const memberType = 'productionHouseMember'

            const request = axios.post<APIResponse<ProductionHouseMemberAuthResponse>>(
                `/api/v1/${memberType}/sign-ins/linkedin`,
                { accessToken },
            );

            const response = await useErrorHandling(request);

            this.$patch({
                auth: response.data.data
            })
        },
        async linkedinSignInAsFreelancer (accessToken: string) {
            const memberType = 'freelanceMember'

            const request = axios.post<APIResponse<FreelancerMemberAuthResponse>>(
                `/api/v1/${memberType}/sign-ins/linkedin`,
                { accessToken },
            );

            const response = await useErrorHandling(request);

            this.$patch({
                auth: response.data.data
            })
        },
        async googleSignInAsProductionHouse (googleCredentials: string) {
            const memberType = 'productionHouseMember'

            const request = axios.post<APIResponse<ProductionHouseMemberAuthResponse>>(
                `/api/v1/${memberType}/sign-ins/google`,
                { googleCredentials },
            );

            const response = await useErrorHandling(request);

            this.$patch({
                auth: response.data.data
            })
        },
        async googleSignInAsFreelancer (googleCredentials: string) {
            const memberType = 'freelanceMember'

            const request = axios.post<APIResponse<FreelancerMemberAuthResponse>>(
                `/api/v1/${memberType}/sign-ins/google`,
                { googleCredentials },
            );

            const response = await useErrorHandling(request);

            this.$patch({
                auth: response.data.data
            })
        },
        async signUpEmailPwdAsProductionHouse ( payload: SignUpRequest ) {
            
            const request = axios.post<APIResponse<ProductionHouseMemberAuthResponse>>(
                `${endpoints.PROD_HOUSE}/sign-ups/username-pwd`,
                { 
                    name: payload.name,
                    email: payload.email,
                    password: payload.password,
                 }
            )

            const response = await useErrorHandling(request);

            this.$patch({
                auth: response.data.data
            });
        },
        async signUpEmailPwdAsFreelancer ( payload: SignUpRequest ) {
            
            const request = axios.post<APIResponse<FreelancerMemberAuthResponse>>(
                `${endpoints.FREELANCER}/sign-ups/username-pwd`,
                { 
                    name: payload.name,
                    email: payload.email,
                    password: payload.password,
                 }
            )

            const response = await useErrorHandling(request);

            this.$patch({
                auth: response.data.data
            });
        },
        async googleSignUpAsProductionHouse ( googleCredentials: string ) {
            
            const request = axios.post<APIResponse<ProductionHouseMemberAuthResponse>>(
                `${endpoints.PROD_HOUSE}/sign-ups/google`,
                { googleCredentials }
            )

            const response = await useErrorHandling(request);

            this.$patch({
                auth: response.data.data
            });

            return response.data.data
        },
        async googleSignUpAsFreelancer ( googleCredentials: string ) {
            
            const request = axios.post<APIResponse<FreelancerMemberAuthResponse>>(
                `${endpoints.FREELANCER}/sign-ups/google`,
                { googleCredentials }
            )

            const response = await useErrorHandling(request);

            this.$patch({
                auth: response.data.data
            });

            return response.data.data
        },
        async linkedinSignUpAsProductionHouse ( accessToken: string ) {
            
            const request = axios.post<APIResponse<ProductionHouseMemberAuthResponse>>(
                `${endpoints.PROD_HOUSE}/sign-ups/linkedin`,
                { accessToken }
            )

            const response = await useErrorHandling(request);

            this.$patch({
                auth: response.data.data
            });

            return response.data.data
        },
        async linkedinSignUpAsFreelancer ( accessToken: string ) {
            
            const request = axios.post<APIResponse<FreelancerMemberAuthResponse>>(
                `${endpoints.FREELANCER}/sign-ups/linkedin`,
                { accessToken }
            )

            const response = await useErrorHandling(request);

            this.$patch({
                auth: response.data.data
            });

            return response.data.data
        },
        async editProductionHouseProfileDetails (data: ProductionHouseProfileDetailsFormFields) {
            const formData = serialize(data);

            const request = axios.put<APIResponse<ProductionHouseMember>>(
                `${endpoints.PROD_HOUSE}/profile-details`,
                formData,
            )

            const response = await useErrorHandling(request);

            this.$patch({
                auth: {
                    productionHouseMember: response.data.data
                }
            });
        },
        async editFreelancerProfileDetails (data: FreelancerProfileDetailsFormFields) {
            const formData = serialize(data);

            const request = axios.put<APIResponse<FreelancerMember>>(
                `${endpoints.FREELANCER}/sign-ups/profile-details`,
                formData,
            )

            const response = await useErrorHandling(request);

            this.$patch({
                auth: {
                    freelanceMember: response.data.data
                }
            });
        },
        async loadMemberApprovalStatus() {
            this.isBusy = true;

            const request = axios.get<APIResponse<ProductionHouseMember|FreelancerMember>>(
                `${endpoints.MEMBERS}`
            )

            const response = await useErrorHandling(request)

            this.member!.approvalStatus = response.data.data.approvalStatus
            
            if (response.data.data.approvalStatus?.name !== 'Rejected') {
                this.member!.approverRemarks = ''
            }
            else {
                this.member!.approverRemarks = response.data.data.approverRemarks
            }
        },
        async verifyEmailAdress(payload: EmailVerificationRequest) {
            const request = axios.post<APIResponse<EmailVerificationRequest>>(
                `${endpoints.MEMBERS}/verification-code`,
                { 
                    emailAddress: payload.emailAddress, 
                    verificationCode: payload.verificationCode 
                }
            )

            const response = await useErrorHandling(request)

            return response.data.isSuccess.valueOf()
        },
        async productionHouseResetPasswordRequest (productionHouseMember: string) {
            const request = axios.post<APIResponse<any>>(
                `${endpoints.PROD_HOUSE}/reset-password-request`,
                undefined,
                {params: { productionHouseMember }}
            )
            await useErrorHandling(request);
        },
        async freelancerResetPasswordRequest (freelanceEmail: string) {
            const request = axios.post<APIResponse<any>>(
                `${endpoints.FREELANCER}/reset-password-request`,
                undefined,
                {params: { freelanceEmail }}
            )
            await useErrorHandling(request);
        },
        async resetOldPassword(payload: ResetPasswordRequest) {
            const request = axios.post<APIResponse<any>>(
                `${endpoints.MEMBERS}/password-resets`,
                {
                    email: payload.email,
                    resetToken: payload.resetToken,
                    newPassword: payload.newPassword
                }
            )
            await useErrorHandling(request)
        },
        async updateCurrentPassword (payload: UpdateCurrentPassword) {
            this.isBusy = false;
            const request = axios.put<APIResponse<ProductionHouseMember|FreelancerMember>>(
                `/api/v1/password-change`,
                {
                    currentPassword: payload.currentPassword,
                    newPassword: payload.newPassword
                },
                { suppressAuthCheckRedirect: true }
            )

            await useErrorHandling(request)
        },
        saveToStorage() {
            localStorage.removeItem(storageKey);
            sessionStorage.removeItem(storageKey);
            Cookies.remove(appConstants.AUTH_TOKEN_COOKIE_NAME);

            const authJson = JSON.stringify(this.auth);
            if (this.rememberMe) {
                localStorage.setItem(storageKey, authJson);
            } else {
                sessionStorage.setItem(storageKey, authJson);
            }
            Cookies.set(appConstants.AUTH_TOKEN_COOKIE_NAME, this.auth.accessToken);
        },
    }
})

export async function portalAuthPlugin() {
    const store = useBroadcastPortalAuthStore();
    
    // const cookieAccessToken = Cookies.get(appConstants.AUTH_TOKEN_COOKIE_NAME);
    // const storageAccessToken = store.auth.accessToken;

    // // No cookie and no storage access token
    // if (!cookieAccessToken && !storageAccessToken) return;

    // // Got cookie but no storage access token
    // if (cookieAccessToken && !storageAccessToken) {
    //     console.log(cookieAccessToken, storageAccessToken)
    //     // Try to restore auth data from cookie
    //     // await store.checkCookieWithServer()
    // }

    store.$subscribe(() => {
        store.saveToStorage();
    })
}