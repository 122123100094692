import { APIResponse, PaginatedData } from "@/modules/core/types";
import { defineStore } from "pinia";
import { Portfolio, PortfolioListing } from "../types/portfolio";
import axios from "axios";
import { useErrorHandling } from "@/modules/core/composables";
import endpoints from "../endpoints";

export const usePortfoliosStore = defineStore('portfolios', {
    state: () => ({
        portfolioFilters: {
            searchTerms: '',
            pageNumber: 1,
            pageSize: 9,
        },
        portfolioPaginatedResults: null as PaginatedData<PortfolioListing>|null,
        isBusy: false
    }),
    actions: {
        async getPortfolioResult (suppressAuthToast = false) {
            this.isBusy = true;
            let params: any = { ...this.portfolioFilters }

            try{
                const request = axios.get<APIResponse<PaginatedData<PortfolioListing>>>(
                    `${endpoints.MEMBERS}/portfolio-showcases`,
                    { params }
                )
                const response = await (!suppressAuthToast ? useErrorHandling(request) : request);

                this.$patch({
                    portfolioPaginatedResults: response.data.data
                })
            }
            catch (error) {
                console.log(error);
            }
            finally {
                this.isBusy = false;
            }
        }
    }
})