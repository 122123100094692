import { createPinia } from 'pinia';
import { portalAuthPlugin } from './auth';

const pinia = createPinia()

pinia.use(portalAuthPlugin)

export default pinia;

export * from './auth';
export * from './common-data';
export * from './services';
export * from './service';
export * from './portfolios';
export * from './portfolio';
export * from './advertisements';
export * from './advertisement';
