import { getMetaTagValue } from "@/modules/core/utilities/dom";

export default {
    AUTH_STORAGE_KEY_NAME: '',
    AUTH_TOKEN_COOKIE_NAME: 'AITI.Broadcast.Member.AccessToken',
    AUTH_TOKEN_HTTP_HEADER_NAME: 'x-member-authorization',
    AUTH_INTERCEPTOR_WHITELIST: [
        '/api/v1/production-houses/sign-ins',
        '/api/v1/freelancers/sign-ins',
    ],

    SIGNIN_PAGE_URL: '/post-login/#/sign-in',
    AUTH_METHOD_KEY_EMAIL_PASSWORD: '4071f652-f80a-41f1-b165-7ac6dc98663f',
    AUTH_METHOD_KEY_GOOGLE: '735f41bd-6168-4298-beca-4c23878b4cfe',
    AUTH_METHOD_KEY_LINKEDIN: '9e9f0c4c-84cf-48e3-8c83-3749a52f40fe',

    GOOGLE_CLIENT_ID: getMetaTagValue('google-client-id'),
}